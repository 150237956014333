.s-cta-contacts {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.s-cta-contacts__title {
  margin-bottom: 1rem;
  font-size: 3.5rem;
  line-height: 4rem;
  letter-spacing: -0.0267857143pxem;
}

.s-cta-contacts__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(356px, auto) minmax(356px, auto) minmax(356px, 1fr);
  grid-template-columns: minmax(356px, auto) minmax(356px, auto) minmax(356px, 1fr);
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 6rem;
  font-size: 1.5rem;
  line-height: 100%;
}

.s-cta-contacts__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 4rem;
}

.s-cta-contacts__list li span {
  line-height: 2rem;
}

.s-cta-contacts__list li .s-cta-contacts__list-phone,
.s-cta-contacts__list li .s-cta-contacts__list-mail {
  min-width: 0px;
}

.s-cta-contacts__list li .s-cta-contacts__list-phone> :not([hidden])~ :not([hidden]),
.s-cta-contacts__list li .s-cta-contacts__list-mail> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.s-cta-contacts__list li .s-cta-contacts__list-phone li,
.s-cta-contacts__list li .s-cta-contacts__list-mail li {
  width: 100%;
  max-width: 100%;
  word-break: break-all;
}

.s-cta-contacts__list li .s-cta-contacts__list_soc {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 1.5rem;
  -moz-column-gap: 4rem;
  -webkit-column-gap: 4rem;
  column-gap: 4rem;
  letter-spacing: -0.02em;
}

.s-cta-contacts__list li .s-cta-contacts__list_soc li {
  font-family: InterRegular;
  text-decoration-line: underline;
}

@media (max-width: 79.9988em) {
  .s-cta-contacts__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3.5rem;
  }
}

@media (max-width: 61.9988em) {
  .s-cta-contacts__list {
    -ms-grid-columns: (minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
  }
}

@media (max-width: 47.9988em) {
  .s-cta-contacts__title {
    margin-bottom: 4rem;
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: 0;
  }

  .s-cta-contacts__list {
    margin-left: auto;
    max-width: 28.125rem;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.00625em;
  }

  .s-cta-contacts__list li {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0.75rem;
    font-family: InterMedium;
  }

  .s-cta-contacts__list li span {
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0;
  }

  .s-cta-contacts__list li .s-cta-contacts__list_soc {
    row-gap: 1.5rem;
    -moz-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
    line-height: 1.5rem;
    letter-spacing: 0.009375em;
  }
}